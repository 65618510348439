<template>
  <div class="dashboard">
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="10">
          <v-card flat>
            <v-layout child-flex>
              <v-card class="pa-3 grey lighten-4" flat>
                <v-card-title>
                  <h1 class="subheading grey--text">Dashboard</h1>
                  <v-spacer></v-spacer>
                  <v-btn dark class="pink" @click="$router.push({name: 'Settings'})">
                    <v-icon left>mdi-plus</v-icon>Create
                  </v-btn>
                </v-card-title>
                <!-- <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="twists"
                  :search="search"
                  class="grey lighten-4"
                  @click:row="seeDetails"
                ></v-data-table>-->
              </v-card>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>